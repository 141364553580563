import { getCollections } from "../models";

const collections = getCollections();

// const cases = [
//   { id: "local", title: "國內實績案例" },
//   { id: "abroad", title: "國外實績案例" },
// ];

export const menuSection = {
  menus: [
    {
      id: "home",
      path: "/",
      title: "首頁",
    },
    {
      id: "products",
      title: "產品",
      children: collections.map(({ id, title }) => ({
        id,
        title,
        path: `/collections/${id}`,
      })),
    },
    // {
    //   id: "cases",
    //   path: "/cases",
    //   title: "使用案例",
    //   // children: cases.map(({ id, title }) => ({
    //   //   id,
    //   //   title,
    //   //   path: `/cases/${id}`,
    //   // })),
    // },
    {
      id: "about",
      path: "/about",
      title: "關於奕貿",
    },
    {
      id: "contact-us",
      path: "/contact-us",
      title: "聯絡我們",
    },
  ],
};

export const footerSection = {
  footers: [
    {
      id: "products",
      title: "所有產品",
      path: "/collections/all",
      children: collections.slice(1).map(({ id, title }) => ({
        id,
        title,
        path: `/collections/${id}`,
      })),
    },
    // {
    //   id: "cases",
    //   path: "/cases",
    //   title: "使用案例",
    //   children: [
    //     { id: "local", title: "國內案例", path: "/cases#local" },
    //     { id: "abroad", title: "國外案例", path: "/cases#abroad" },
    //   ],
    // },
    {
      id: "about",
      path: "/about",
      title: "關於奕貿",
      children: [
        { id: "value", title: "公司簡介", path: "/about#value" },
        { id: "media", title: "媒體報導", path: "/about#media" },
      ],
    },
  ],
};
