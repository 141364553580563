import { Link } from "react-router-dom";
import {
  heroSection,
  collectionSection,
  featureSection,
  // customerSection,
} from "../models/home";

function HeroImage({ id, src, alt }) {
  return (
    <div key={id} className="relative">
      <img
        src={src}
        alt={alt}
        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
      />
      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10 botton-10px">
        <span className="absolute bottom-1 left-2 text-white text-xs bg-slate-600">
          {alt}
        </span>
      </div>
    </div>
  );
}

function HeroSection({ title, subtitle, button, images }) {
  return (
    <section>
      <div className="relative isolate">
        <svg
          className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
          />
        </svg>
        <div
          className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
          aria-hidden="true"
        >
          <div
            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
            }}
          />
        </div>
        <div className="overflow-hidden">
          <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
              <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  {title}
                </h1>
                <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  {subtitle}
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Link
                    to="/collections/all"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {button}
                  </Link>
                </div>
              </div>
              <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                  {images.col1.map(({ id, ...x }) => (
                    <HeroImage key={id} {...x} />
                  ))}
                </div>
                <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                  {images.col2.map(({ id, ...x }) => (
                    <HeroImage key={id} {...x} />
                  ))}
                </div>
                <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                  {images.col3.map(({ id, ...x }) => (
                    <HeroImage key={id} {...x} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function CollectionTitle({ collection }) {
  return (
    <>
      <div className="absolute top-4 sm:left-4 left-2">
        {collection.topTexts.map((txt, idx) => (
          <div
            key={idx}
            className="mt-1 px-2 text-white sm:text-sm text-xs bg-sky-700 te text-center rounded-2xl"
          >
            {txt}
          </div>
        ))}
      </div>
      <div>
        <p
          aria-hidden="true"
          className="mb-2 text-xs bg-amber-300 whitespace-nowrap"
        >
          {collection.bottomText}
        </p>
        <h3 className="font-semibold text-white">
          <Link to={collection.path}>
            <span className="absolute inset-0" />
            {collection.title}
          </Link>
        </h3>
        <p aria-hidden="true" className="mt-1 text-sm text-white">
          {collection.subtitle}
        </p>
      </div>
    </>
  );
}

function CollectionSection({ title, collections }) {
  return (
    <section aria-labelledby="category-heading" className="bg-gray-50">
      <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="sm:flex sm:items-baseline sm:justify-between">
          <h2
            id="category-heading"
            className="text-2xl font-bold tracking-tight text-gray-900"
          >
            {title}
          </h2>
          <Link
            to="/collections/all"
            className="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block"
          >
            所有產品
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
          {collections.map((collection, idx) => {
            if (idx === 0) {
              return (
                <div
                  key={collection.id}
                  className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2"
                >
                  <img
                    src={collection.img}
                    alt={collection.title}
                    loading="lazy"
                    className="object-cover object-center group-hover:opacity-75"
                  />
                  <div
                    aria-hidden="true"
                    className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
                  />

                  <div className="flex items-end sm:p-4 p-2">
                    <CollectionTitle collection={collection} />
                  </div>
                </div>
              );
            }

            return (
              <div
                key={collection.id}
                className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full"
              >
                <img
                  src={collection.img}
                  alt={collection.title}
                  loading="lazy"
                  className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                />
                <div
                  aria-hidden="true"
                  className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
                />
                <div className="flex items-end sm:p-4 p-2 sm:absolute sm:inset-0">
                  <CollectionTitle collection={collection} />
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-6 sm:hidden">
          <Link
            to="/collections/all"
            className="block text-sm font-semibold text-indigo-600 hover:text-indigo-500"
          >
            所有產品
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      </div>
    </section>
  );
}

function FeatureSection({ title, features }) {
  return (
    <section
      aria-labelledby="perks-heading"
      className="border-t border-gray-200 bg-gray-50"
    >
      <h2 id="perks-heading" className="sr-only">
        {title}
      </h2>

      <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-0">
          {features.map((feature) => (
            <div
              key={feature.name}
              className="text-center md:flex md:items-start md:text-left lg:block lg:text-center"
            >
              <div className="md:flex-shrink-0">
                <div className="flow-root">
                  <img
                    src={feature.imageUrl}
                    alt={feature.name}
                    loading="lazy"
                    className="-my-1 mx-auto h-16 w-16 object-contain"
                  />
                </div>
              </div>
              <div className="mt-6 md:ml-4 md:mt-0 lg:ml-0 lg:mt-6">
                <h3 className="text-base font-medium text-gray-900">
                  {feature.name}
                </h3>
                <p className="mt-3 text-sm text-gray-500">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function CustomerSection({ title, subtitle, button, clients }) {
  return (
    <section>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
            <div className="mx-auto w-full max-w-xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                {title}
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">{subtitle}</p>
              <div className="mt-8 flex items-center gap-x-6">
                <Link
                  to="#"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {button}
                </Link>
              </div>
            </div>
            <div className="mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
              {clients.map((client) => (
                <img
                  src={client.src}
                  alt={client.name}
                  loading="lazy"
                  width={client.width}
                  height={client.height}
                  className="max-h-12 w-full object-contain object-left"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default function Home() {
  return (
    <main>
      <HeroSection {...heroSection} />
      <CollectionSection {...collectionSection} />
      <FeatureSection {...featureSection} />
      {/* <CustomerSection {...customerSection} /> */}
    </main>
  );
}
