// Hero section
export const heroSection = {
  title: "您的售票解決方案",
  subtitle:
    "從快剪售票機至B2B解決方案，我們的產品和服務宗旨，在協助經營者和商家共同找到銷售管理的順暢管道。",
  button: "探索更多",
  images: {
    col1: [{ id: "hero-1", src: "/images/home/hero-1.jpg", alt: "JIT連鎖店" }],
    col2: [
      { id: "hero-2", src: "/images/home/hero-2.jpg", alt: "香港神石連鎖店" },
      { id: "hero-3", src: "/images/home/hero-3.jpg", alt: "台中溫泉會館" },
    ],
    col3: [
      { id: "hero-4", src: "/images/home/hero-4.jpg", alt: "牛庵" },
      { id: "hero-5", src: "/images/home/hero-5.jpg", alt: "桌遊連鎖店" },
    ],
  },
};

export const collectionSection = {
  title: "多樣化的收票機",
  collections: [
    {
      id: "touchscreen",
      title: "觸控螢幕售票機",
      subtitle: "餐廳 / 快速剪髮 / 展場門票",
      topTexts: ["12/15/22吋", "三種不同螢幕尺寸選擇"],
      bottomText: "支援電子支付、電子發票、QRcode掃描、線上點餐",
      img: "/images/home/collection-1.jpg",
      path: "/collections/touchscreen",
    },
    {
      id: "pos",
      title: "日式傳統點餐機",
      subtitle: "拉麵店 / 日式餐廳 / 一般餐廳",
      topTexts: ["VT-28 & VT-36", "懷舊風", "簡單易懂"],
      bottomText: "支援電子支付、電子發票、QRcode掃描、線上點餐",
      img: "/images/home/collection-2.jpg",
      path: "/collections/pos",
    },
    {
      id: "button",
      title: "按鍵式售票機",
      subtitle: "快剪店 / 展場門票 / 團膳餐飲",
      topTexts: ["VT & VR 系列", "操作簡單", "價格親民"],
      bottomText: "現金交易、VR系列支援找零及二聯式發票",
      img: "/images/home/collection-3.jpg",
      path: "/collections/button",
    },
  ],
};

export const featureSection = {
  title: "功能",
  features: [
    {
      name: "後台管理",
      imageUrl: "/images/home/feature-1.svg",
      description: "產品設定 / 金額設定 / 報表 ",
    },
    {
      name: "售票機外觀設計",
      imageUrl: "/images/home/feature-2.svg",
      description: "外觀大圖設計 / 貼圖",
    },
    {
      name: "票券客製化",
      imageUrl: "/images/home/feature-3.svg",
      description: "票券設計 / 排版",
    },
    {
      name: "模組化",
      imageUrl: "/images/home/feature-4.svg",
      description:
        "傳統發票 / 電子發票 / 現金支付 / 電子支付 / 信用卡 / 悠遊卡",
    },
  ],
};

export const customerSection = {
  title: "我們的客戶",
  subtitle:
    "我們的客戶不僅是我們的業務夥伴，更是我們的支持者和合作伙伴。他們的成功與滿足是我們追求的目標，我們一直致力於為他們提供超出預期的服務和價值。",
  button: "國內外使用案例",
  clients: [
    {
      id: "1",
      name: "Tuple",
      src: "https://tailwindui.com/img/logos/tuple-logo-gray-900.svg",
      width: 105,
      height: 48,
    },
    {
      id: "2",
      name: "Reform",
      src: "https://tailwindui.com/img/logos/reform-logo-gray-900.svg",
      width: 104,
      height: 48,
    },
    {
      id: "3",
      name: "SavvyCal",
      src: "https://tailwindui.com/img/logos/savvycal-logo-gray-900.svg",
      width: 105,
      height: 48,
    },
    {
      id: "4",
      name: "Laravel",
      src: "https://tailwindui.com/img/logos/laravel-logo-gray-900.svg",
      width: 136,
      height: 48,
    },
    {
      id: "5",
      name: "Transistor",
      src: "https://tailwindui.com/img/logos/transistor-logo-gray-900.svg",
      width: 158,
      height: 48,
    },
    {
      id: "6",
      name: "Statamic",
      src: "https://tailwindui.com/img/logos/statamic-logo-gray-900.svg",
      width: 147,
      height: 48,
    },
  ],
};
