export const titleSection = {
  title: "關於奕貿",
  subtitle:
    "奕貿科技成立30餘年來是一家致力於研發設計系統整合的專業公司，除客戶指定的冷門自動化設備開發外，親民的快速剪髮售票機、日式食券機、門票等客製化各式各樣售票機。奕貿科技開發各式各樣售票機為客戶打開經營之門檻，節省人力及管理的成本，俾使客戶專注於專業技術的執行，除高科技業外及傳統產業之應用，我們研發的技術為產業創造新的事業里程碑，自動化設備及售票機系統成為客戶最佳事業的經營夥伴。",
};

export const timelineSection = {
  timeline: [
    {
      name: "自動化設備客製化",
      description:
        "公司成立，首先專注於為新竹科學園區及國內上市與國際公司，客製化自動化設備解決方案，以提高產品生產效率和質量控制，協助客戶提高良率及提供可量化測試數據，以協助客戶順利接大單。",
      date: "1993年10月公司成立",
      dateTime: "1993-10",
    },
    {
      name: "自動販賣機及無線衛星電波鐘",
      description:
        "奕貿科技累積的自動化技術能力，因應客戶之需求進入自動販賣機量產；代表作品：聯合報體系報紙自動販賣機及多媒體自動廣告播放機，並延伸學校簡章販賣機及旅遊商品、電話卡、口罩、便當販賣機。無線衛星電波鐘專利世界第二，獲得德美日大陸台灣等各項專利與知名鐘錶工廠量產合作。",
      date: "2001年",
      dateTime: "2001-01",
    },
    {
      name: "自動收銀找零售票機的產生",
      description:
        "在觀察市場對於更高效率和便利的售票方式需求後，奕貿科技研發量產觸控螢幕售票機、控制器系統按鍵式售票機及觸控螢幕整合燈箱片售票機，配合不同行業客戶需求開發各式各樣售票機。",
      date: "2006年",
      dateTime: "2006-01",
    },
    {
      name: "售票機網路應用及第三方支付導入",
      description:
        "因應網路及金流環境快速的變化，公司開始研發手機點餐、外賣點餐及第三方支付創新整合系統，以滿足客戶與大環境之需求；在新一代年輕人之創新，並注入新的娛樂領域；如連鎖桌遊業、老年人聚會之麻將館….. 。",
      date: "2019年迄今",
      dateTime: "2019-01",
    },
  ],
};

export const imageSection = {
  imageSrc: "/images/about/section-image.jpg",
  imageAlt: "mountain",
};

export const valueSection = {
  title: "為客戶創造價值",
  subtitle:
    "奕貿科技自動化測試設備產品，協助客戶精準快速接單外，售票機也協助國内外客戶順利展店，協助客戶充分展現了公司的多元化和市場適應能力，為客戶帶來更多穩定及展店機會。",
  values: [
    {
      name: "創新技術領先",
      description:
        "本公司一直以創新研發為核心，不僅跟隨技術趨勢，而且在多個領域中都具有領先地位，確保客戶可以獲得市場上最先進的產品。",
    },
    {
      name: "廣泛的產業涵蓋",
      description:
        "售票機遍及高科技公司&機場團膳及傳統產業大小型店面、觀光風景區…..等，客戶群在快剪&Salon店、餐廳、門票、停車場、推拿業、連鎖店之遊樂場所、洗車場…..等。",
    },
    {
      name: "穩定且高效的自動化解決方案",
      description:
        "自動化取代人工的誤差/24小時以上及萬次以上數據/與客戶隨時監測。",
    },
    {
      name: "專業的客戶支持",
      description: "奕貿科技研發與客戶管理階層，不斷地省力化精準化市場導向。",
    },
    {
      name: "持續的研發投資",
      description: "奕貿科技與客戶要求的完美；共同攜手合作創造新的管理模式。",
    },
    {
      name: "豐富的專利成果",
      description:
        "獲得德美日大陸台灣等數十項專利；電波鐘專利並與知名品牌合作行銷全世界。",
    },
  ],
};

export const mediaSection = {
  title: "媒體報導",
  medias: [
    {
      id: 1,
      title: "你創意經營的新夥伴，揭密方便有趣的售票機",
      author: "中天綜合36台 - 美的in台灣",
      link: "https://youtu.be/JAQkHhTI17Y",
    },
    {
      id: 2,
      title: "帶給我們幸福感的隱形冠軍",
      author: "新竹市政府",
      link: "https://dep-construction.hccg.gov.tw/ch/home.jsp?id=7&parentpath=&mcustomize=municipalnews_view.jsp&toolsflag=Y&dataserno=202109130003&t=MunicipalNews&mserno=201601300071",
    },
    {
      id: 3,
      title: "按鈕投幣點餐 拉麵店意外爆紅",
      author: "民視新聞",
      link: "https://youtu.be/nzMLiWkQPZU",
    },
  ],
};
