import items from "./items.json";
const getItems = () => items;

const getItem = (sku) => {
  const items = getItems();
  return items.find((p) => p.sku === sku);
};

const getCollections = () => {
  return [
    { id: "all", title: "所有產品" },
    { id: "button", title: "按鍵式售票機" },
    { id: "touchscreen", title: "觸控螢幕售票機" },
    { id: "pos", title: "日式傳統點餐機" },
    { id: "card", title: "自助售卡機" },
  ];
};

const getCollectionById = (collectionId) => {
  return getCollections().find(({ id }) => id === collectionId);
};

const getCollectionItems = (collectionId = "all") => {
  const items = getItems();
  if (collectionId === "all") {
    return items;
  }

  const collections = getCollections();
  const collection = collections.find(({ id }) => id === collectionId);
  if (!collection) return [];

  return items.filter((item) => item.collection === collection.title);
};

export { getItem, getCollectionItems, getCollections, getCollectionById };
