import React from "react";
import {
  titleSection,
  timelineSection,
  imageSection,
  valueSection,
  mediaSection,
} from "../models/about";

function Background() {
  return (
    <div
      className="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
      aria-hidden="true"
    >
      <div
        className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
        style={{
          clipPath:
            "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
        }}
      />
    </div>
  );
}

function TitleSection({ title, subtitle }) {
  return (
    <div className="px-6 pt-14 lg:px-8">
      <div className="mx-auto mb-32 max-w-2xl pt-24 text-center sm:pt-40">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          {title}
        </h2>
        <p className="mt-6 text-md leading-8 text-gray-600">{subtitle}</p>
      </div>
    </div>
  );
}

function TimelineSection({ timeline }) {
  return (
    <section id="value" aria-labelledby="media-heading" className="pt-32">
      <div className="mx-auto my-32 -mt-8 max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {timeline.map((item) => (
            <div key={item.name}>
              <time
                dateTime={item.dateTime}
                className="flex items-center text-sm font-semibold leading-6 text-indigo-600"
              >
                <svg
                  viewBox="0 0 4 4"
                  className="mr-4 h-1 w-1 flex-none"
                  aria-hidden="true"
                >
                  <circle cx={2} cy={2} r={2} fill="currentColor" />
                </svg>
                {item.date}
                <div
                  className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                  aria-hidden="true"
                />
              </time>
              <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                {item.name}
              </p>
              <p className="mt-1 text-base leading-7 text-gray-600">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function ImageSection({ imageSrc, imageAlt }) {
  return (
    <div className="mt-0 xl:mx-auto xl:max-w-7xl xl:px-8">
      <img
        src={imageSrc}
        alt={imageAlt}
        loading="lazy"
        className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
      />
    </div>
  );
}

function ValuesSection({ title, subtitle, values }) {
  return (
    <div className="mx-auto my-24 mt-8 max-w-7xl px-6 sm:mt-40 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {title}
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">{subtitle}</p>
      </div>
      <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {values.map((value) => (
          <div key={value.name}>
            <dt className="font-semibold text-gray-900">{value.name}</dt>
            <dd className="mt-1 text-gray-600">{value.description}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

function MediaSection({ title, medias }) {
  return (
    <section
      id="media"
      aria-labelledby="media-heading"
      className="relative mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8 lg:py-32"
    >
      <div className="mx-auto max-w-2xl lg:max-w-none">
        <h2
          id="testimonial-heading"
          className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
        >
          {title}
        </h2>

        <div className="mt-16 space-y-16 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
          {medias.map(({ id, title, author, link }) => (
            <blockquote key={id} className="sm:flex lg:block">
              <svg
                width={24}
                height={18}
                viewBox="0 0 24 18"
                aria-hidden="true"
                className="flex-shrink-0 text-gray-300"
              >
                <path
                  d="M0 18h8.7v-5.555c-.024-3.906 1.113-6.841 2.892-9.68L6.452 0C3.188 2.644-.026 7.86 0 12.469V18zm12.408 0h8.7v-5.555C21.083 8.539 22.22 5.604 24 2.765L18.859 0c-3.263 2.644-6.476 7.86-6.451 12.469V18z"
                  fill="currentColor"
                />
              </svg>
              <div className="mt-8 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-10">
                <a
                  className="text-lg text-red-400"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {title}
                </a>
                <cite className="mt-4 block font-semibold not-italic text-gray-900">
                  {author}
                </cite>
              </div>
            </blockquote>
          ))}
        </div>
      </div>
    </section>
  );
}

export default function About() {
  return (
    <div className="bg-white">
      <main className="relative isolate">
        <Background />
        <TitleSection {...titleSection} />
        <TimelineSection {...timelineSection} />
        <ImageSection {...imageSection} />
        <ValuesSection {...valueSection} />
        <MediaSection {...mediaSection} />
      </main>
    </div>
  );
}
