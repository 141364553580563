import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { classNames } from "../utils";
import { title, subtitle, tabs, CASE_TPYE_TEXT } from "../models/cases";

function ColText({ name, sku, description, features }) {
  return (
    <div>
      <div className="border-b border-gray-200 pb-6">
        <h2 className="text-3xl font-medium text-gray-900 sm:text-4xl">
          {name}
        </h2>
        <p className="mt-4 tracking-tight text-gray-500 ">{description}</p>

        <p className="mt-4 font-bold text-gray-800 ">{`規格：${sku}`}</p>
      </div>

      <dl className="mt-4">
        {features.map((feature, idx) => (
          <dt key={idx} className="text-sm text-gray-600">
            {feature.name}
          </dt>
        ))}
      </dl>
    </div>
  );
}

function ColImages({ primaryImage, images }) {
  return (
    <div>
      <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
        <img
          src={primaryImage.src}
          alt={primaryImage.title}
          loading="lazy"
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className="mt-4 grid grid-cols-2 gap-4 sm:mt-6 sm:gap-6 lg:mt-8 lg:gap-8">
        {images.map((image, idx) => (
          <div
            key={idx}
            className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100"
          >
            <img
              src={image.src}
              alt={image.title}
              loading="lazy"
              className="h-full w-full object-cover object-center"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function CaseItem({ name, sku, description, features, primaryImage, images }) {
  return (
    <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
      <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
        <ColText
          name={name}
          sku={sku}
          description={description}
          features={features}
        />
        <ColImages primaryImage={primaryImage} images={images} />
      </div>
    </div>
  );
}

function TabItem(tab) {
  return (
    <Tab.Panel key={tab.name} className="space-y-16 pt-10 lg:pt-16">
      <div className="mx-auto my-24 max-w-3xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {tab.name}
        </h2>
        <p className="mt-4 text-gray-500">{tab.description}</p>
      </div>
      {tab.cases.map((caseData, idx) => (
        <CaseItem key={idx} {...caseData} />
      ))}
    </Tab.Panel>
  );
}

export default function Cases({ type }) {
  return (
    <div className="bg-white">
      <section
        aria-labelledby="cases"
        className="mx-auto max-w-7xl py-32 sm:px-2 lg:px-8"
      >
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
          <div className="max-w-3xl">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {`${CASE_TPYE_TEXT[type]}${title}`}
            </h2>
            <p className="my-12 text-gray-500">{subtitle}</p>
          </div>

          <Tab.Group as="div" className="mt-4">
            {/* Tab menus */}
            <div className="-mx-4 flex overflow-x-auto sm:mx-0">
              <div className="flex-auto border-b border-gray-200 px-4 sm:px-0">
                <Tab.List className="-mb-px flex space-x-10">
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.name}
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 py-6 text-sm font-medium"
                        )
                      }
                    >
                      {tab.name}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
            </div>

            {/* Tab content */}
            <Tab.Panels as={Fragment}>
              {tabs.map((tab) => (
                <TabItem key={tab.name} {...tab} />
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </section>
    </div>
  );
}
