/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PrinterIcon,
  MapPinIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
import { Outlet, Link, ScrollRestoration } from "react-router-dom";
import { classNames } from "../utils";
import { menuSection, footerSection } from "../models/root";

const DropdownMenu = ({ menu }) => {
  return (
    <Popover className="relative">
      <Popover.Button className="flex items-center gap-x-1 text-base font-semibold leading-6 text-gray-900">
        {menu.title}
        <ChevronDownIcon
          className="h-5 w-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
          <div className="p-4">
            {menu.children.map(({ id, title, path }) => (
              <Link
                key={id}
                to={path}
                className="block font-semibold text-gray-900"
              >
                <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-base leading-6 hover:bg-gray-50">
                  {title}
                </div>
              </Link>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

const MobileDropdown = ({ menu, onClose }) => {
  return (
    <Disclosure as="div" className="-mx-3">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
            {menu.title}
            <ChevronDownIcon
              className={classNames(
                open ? "rotate-180" : "",
                "h-5 w-5 flex-none"
              )}
              aria-hidden="true"
            />
          </Disclosure.Button>
          <Disclosure.Panel className="mt-2 space-y-2">
            {menu.children.map(({ id, title, path }) => (
              <Link key={id} to={path} onClick={onClose}>
                <Disclosure.Button
                  as="span"
                  className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {title}
                </Disclosure.Button>
              </Link>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

function Menu({ menus }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white opacity-100 drop-shadow-sm sticky top-0 z-50">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/">
            <span className="sr-only">奕貿科技</span>
            <img className="h-8 w-auto" src="/images/logo.svg" alt="itc-logo" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          {menus.map((menu) => {
            if (menu.children && menu.children.length > 0) {
              return <DropdownMenu key={menu.id} menu={menu} />;
            }

            return (
              <Link
                key={menu.id}
                to={menu.path}
                className="text-base font-semibold leading-6 text-gray-900"
              >
                {menu.title}
              </Link>
            );
          })}
        </Popover.Group>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end"></div>
      </nav>

      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link
              to="/"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-1.5 p-1.5"
            >
              <span className="sr-only">奕貿科技</span>
              <img
                className="h-8 w-auto"
                src="/images/logo.svg"
                alt="itc-logo"
              />
            </Link>

            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {menus.map((menu) => {
                  if (menu.children && menu.children.length > 0) {
                    return (
                      <MobileDropdown
                        key={menu.id}
                        menu={menu}
                        onClose={() => setMobileMenuOpen(false)}
                      />
                    );
                  }

                  return (
                    <Link
                      key={menu.id}
                      to={menu.path}
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {menu.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}

function Footer({ footers }) {
  return (
    <footer
      className="bg-slate-50 divide-y divide-solid"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="space-y-2">
            <div className="flex align-center">
              <img
                className="inline h-8"
                src="/images/logo.svg"
                alt="itc-logo"
              />
              <span className="self-center text-sm ml-4">
                奕貿科技股份有限公司
              </span>
            </div>

            <p className="flex items-center text-sm leading-6 text-gray-600">
              <EnvelopeIcon
                className="h-4 w-4 mr-2 flex-none text-gray-700"
                aria-hidden="true"
              />
              <span> Email: service@greenworks.com.tw</span>
            </p>

            <p className="flex items-center text-sm leading-6 text-gray-600">
              <PhoneIcon
                className="h-4 w-4 mr-2 flex-none text-gray-700"
                aria-hidden="true"
              />
              <span>電話: 03-6668842</span>
            </p>

            <p className="flex items-center text-sm leading-6 text-gray-600">
              <PrinterIcon
                className="h-4 w-4 mr-2 flex-none text-gray-700"
                aria-hidden="true"
              />
              <span>傳真: 03-6668841</span>
            </p>
            <p className="flex items-center text-sm leading-6 text-gray-600">
              <MapPinIcon
                className="h-4 w-4 mr-2 flex-none text-gray-700"
                aria-hidden="true"
              />
              <span>300 新竹市東區埔頂路346巷4號</span>
            </p>
            <p className="text-sm leading-6 text-green-600">
              <a
                href="https://line.me/R/ti/p/@imao?from=page"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  width={100}
                  src="/images/line_qr_code.png"
                  alt="LINE@QR-Code"
                  loading="lazy"
                />
              </a>
            </p>
          </div>

          <div className="mt-16 grid grid-cols-2 sm:grid-cols-3 gap-8 lg:col-span-2 lg:mt-0">
            {footers.map((item) => {
              return (
                <div key={item.id}>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    {item.title}
                  </h3>
                  {item.children && item.children.length > 0 && (
                    <ul className="mt-2 space-y-2">
                      {item.children.map(({ id, title, path }) => (
                        <li key={id}>
                          <Link
                            to={path}
                            className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                          >
                            {title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-400">
            智慧財產權聲明:
            奕貿科技股份有限公司電腦程式、著作、技術受著作權法及國際
            公約之保護，並保留全部權利。
            未經授權擅自複製或散佈本公司網頁圖片&文字、電腦程式、著作、技術之全部或
            部分，將導致嚴厲的民事或刑事處分，且將被依法提起最大範圍 的追訴。
          </p>
          <p className="mt-4 text-xs leading-5 text-gray-500 text-center">
            Copyright &copy; 2023 IMAO technology corporation. All Rights
            Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default function Root() {
  return (
    <div className="border-box w-full">
      <Menu {...menuSection} />
      <Outlet />
      <Footer {...footerSection} />
      <ScrollRestoration />
    </div>
  );
}
