import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import ProductDetail from "./routes/productDetail";
import ProductList from "./routes/productList";
import Index from "./routes";
import ContactUs from "./routes/contactUs";
import About from "./routes/about";
import Cases from "./routes/cases";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Index />,
      },
      {
        path: `/collections/:collection`,
        element: <ProductList />,
      },
      {
        path: "/products/:sku",
        element: <ProductDetail />,
      },
      {
        path: "/cases/local",
        element: <Cases type="local" />,
      },
      {
        path: "/cases/abroad",
        element: <Cases type="abroad" />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
