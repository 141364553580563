import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

export default function Breadcrumb({ pages }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li key="all">
          <div>
            {pages.length === 0 ? (
              <span className="ml-4 text-md text-gray-500">所有產品</span>
            ) : (
              <Link
                to="/collections/all"
                className="text-md text-gray-400 hover:text-gray-500"
              >
                所有產品
                <span className="sr-only">所有產品</span>
              </Link>
            )}
          </div>
        </li>
        {pages.map(({ id, title }, idx) => {
          return (
            <li key={id}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {idx === pages.length - 1 ? (
                  <span className="ml-4 text-md text-gray-500">{title}</span>
                ) : (
                  <Link
                    to={`/collections/${id}`}
                    className="ml-4 text-md text-gray-400 hover:text-gray-500"
                  >
                    {title}
                  </Link>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
