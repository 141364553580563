import React from "react";
import {
  PhoneIcon,
  PrinterIcon,
  EnvelopeIcon,
  ClockIcon,
} from "@heroicons/react/20/solid";

export default function ContactUs() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            <div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                聯絡我們
              </h2>
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
              {/* Email */}
              <div className="rounded-2xl bg-gray-50 p-10">
                <div className="flex items-center">
                  <EnvelopeIcon
                    className="h-4 w-4 mr-2 flex-none text-gray-700"
                    aria-hidden="true"
                  />
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    電子信箱 Email
                  </h3>
                </div>

                <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                  <div>
                    <dt className="sr-only">Email</dt>
                    <dd>
                      <a
                        className="font-semibold text-indigo-600"
                        href="mailto:service@greenworks.com.tw"
                      >
                        service@greenworks.com.tw
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>

              {/* Phone */}
              <div className="rounded-2xl bg-gray-50 p-10">
                <div className="flex items-center">
                  <PhoneIcon
                    className="h-4 w-4 mr-2 flex-none text-gray-700"
                    aria-hidden="true"
                  />
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    電話 Phone No.
                  </h3>
                </div>
                <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                  <div>
                    <dt className="sr-only">電話</dt>
                    <dd>
                      <span className="font-semibold text-indigo-600">
                        03-6668842
                      </span>
                    </dd>
                  </div>

                  <div className="mt-1">
                    <dt className="sr-only">+886-3-6668842</dt>
                    <dd>+886-3-6668842</dd>
                  </div>
                </dl>
              </div>

              {/* Fax */}
              <div className="rounded-2xl bg-gray-50 p-10">
                <div className="flex items-center">
                  <PrinterIcon
                    className="h-4 w-4 mr-2 flex-none text-gray-700"
                    aria-hidden="true"
                  />
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    傳真 Fax No.
                  </h3>
                </div>
                <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                  <div>
                    <dt className="sr-only">傳真</dt>
                    <dd>
                      <span className="font-semibold text-indigo-600">
                        03-6668841
                      </span>
                    </dd>
                  </div>

                  <div className="mt-1">
                    <dt className="sr-only">+886-3-6668841</dt>
                    <dd>+886-3-6668841</dd>
                  </div>
                </dl>
              </div>

              {/* Working hours */}
              <div className="rounded-2xl bg-gray-50 p-10">
                <div className="flex items-center">
                  <ClockIcon
                    className="h-4 w-4 mr-2 flex-none text-gray-700"
                    aria-hidden="true"
                  />
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    上班時間 Working Hours
                  </h3>
                </div>

                <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                  <div>
                    <dt className="sr-only">上班時間</dt>
                    <dd>
                      <span className="font-semibold text-indigo-600">
                        週一至週五 9:00 ~18:00
                      </span>
                    </dd>
                  </div>
                  <div className="mt-1">
                    <dt className="sr-only">其餘時間請來電預約</dt>
                    <dd>其餘時間請來電預約</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-16 lg:grid-cols-3">
            <div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                公司地址
              </h2>
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
              <div className="rounded-2xl bg-gray-50 p-10">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  台灣
                </h3>
                <address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                  <p>300 新竹市埔頂路346巷4號</p>
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
