import React from "react";
import { useParams } from "react-router-dom";
import { getCollections, getItem } from "../models";
import { Breadcrumb } from "../components";

function ProductImage({ src, alt }) {
  return (
    <div className="lg:col-span-3 lg:row-end-1">
      <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
        <img
          src={src}
          alt={alt}
          className="mx-auto max-h-[32rem] object-contain object-center"
        />
      </div>
    </div>
  );
}

function ProductInfo({ sku, title, details, tags }) {
  return (
    <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-4 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
      <div className="flex flex-col-reverse">
        <div className="mt-4">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            {sku}
          </h1>

          <h2 id="information-heading" className="sr-only">
            Product information
          </h2>
          <p className="mt-2 text-lg text-gray-500">{title}</p>
        </div>
      </div>

      {details.map(({ id, title, descriptions }) => {
        if (descriptions.length === 0) return null;
        return (
          <div key={id} className="mt-10 border-t border-gray-200 pt-10">
            <h3 className="text-sm font-medium text-gray-900">{title}</h3>
            <div className="prose prose-sm mt-4 text-gray-500">
              <ul className="pl-4 list-disc">
                {descriptions.map((desc, idx) => (
                  <li key={idx}>{desc}</li>
                ))}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default function ProductDetail() {
  const { sku } = useParams();
  const item = getItem(sku);
  if (!item) return "Not exists";

  const collections = getCollections();
  const collection = collections.find((c) => c.title === item.collection);
  const pages = [];
  if (collection) {
    pages.push(collection);
  }
  pages.push({ id: sku, title: sku });

  return (
    <div className="bg-white">
      <div className="mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="px-4 py-4 sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
          <Breadcrumb pages={pages} />
        </div>
        <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          <ProductImage src={`/images/items/${item.sku}.png`} alt={item.sku} />
          <ProductInfo {...item} />
        </div>
      </div>
    </div>
  );
}
