export const title = "使用案例";
export const subtitle =
  "我們自動化測試設備產品，協助客戶精準快速接單外，售票機也協助國内外客戶順利展店，協助客戶充分展現了公司的多元化和市場適應能力，為客戶帶來更多穩定及展店機會。";

export const tabs = [
  {
    name: "快速剪髮及Salon",
    description:
      "早期百元快速剪髮投錢出票不找零系統，客戶已發展至多樣化服務之觸控螢幕收錢找錢並第三方支付系統。",
    cases: [
      {
        name: "JIT 快速剪髮",
        sku: "vt-01-s-04",
        description:
          "大賣場門市：精緻剪髪。門市：精緻剪髮、專業染髮500元。染護專門店:精緻剪髮、男性染髮399元、女性染髮499元。",
        features: [
          {
            name: "收100紙鈔",
            description:
              "Shaped for steady pours and insulated to prevent burns.",
          },
          {
            name: "收50/10硬幣",
            description:
              "The one button control has a digital readout for setting temperature and turning the kettle on and off.",
          },
        ],
        primaryImage: {
          title:
            "Black kettle with long pour spot and angled body on marble counter next to coffee mug and pour-over system.",
          src: "https://tailwindui.com/img/ecommerce-images/product-feature-09-main-detail.jpg",
        },
        images: [
          {
            title:
              "Detail of temperature setting button on kettle bass with digital degree readout.",
            src: "https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-01.jpg",
          },
          {
            title:
              "Kettle spout pouring boiling water into coffee grounds in pour-over mug.",
            src: "https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-02.jpg",
          },
        ],
      },
    ],
  },
  {
    name: "餐飲業",
    description:
      "餐飲業為拚翻桌率，起初即以最高規格售票機為需求，收錢找錢出票廚房出單至廚房分類出票；在擴及眾多餐飲業客戶認識售票機後，退而求其次為結合售票機管理金流餐廳經營型態也在配合改變，其亦參考其他按鍵式售票機以降低開辦費成本。",
    cases: [],
  },
  {
    name: "門票及觀光&娛樂產業",
    description:
      "不同領域觀光型態類型客製化售票機有按鍵式有觸控螢幕，多樣化整合開發軟體，配合修改頁面&按鍵，以時段系統售票、以客戶別售票、以儲值系統售票等等，奕貿科技了解客戶需求彙整資訊提供專業之建議。",
    cases: [],
  },
  {
    name: "桌遊&麻將&舞廳休閒業者",
    description:
      "國內穩定成長嚴格挑選加盟商之桌遊連鎖店業者，選擇與奕貿科技合作，我們共同把關期使桌遊為真正之遊藝活動，避免年輕人陷入不良場所。麻將&舞廳為提供退休人員，聚會休閒活動場所，售票機之服務價格公正透明化，方便客戶之選擇。",
    cases: [],
  },

  {
    name: "房東小型停車場及學生宿舍之管理",
    description:
      "房東不須投資太大其小型停車場以控制器售票機計時收費管理，房東無法經常在學生宿舍以售卡機儲值電費管理電表，24小時服務的售票機為房東最佳化之管理夥伴。",
    cases: [],
  },
];

export const CASE_TPYE_TEXT = {
  local: "國內",
  abroad: "國外",
};
