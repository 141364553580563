import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getCollectionItems, getCollectionById } from "../models";
import { Badge, Breadcrumb } from "../components";

export default function ProductList() {
  const { collection: collectionId } = useParams();
  const items = getCollectionItems(collectionId);
  const collection = getCollectionById(collectionId);

  const pages = [];
  if (collection && collection.id !== "all") {
    pages.push(collection);
  }

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">{collection.title}</h2>

        <div className="px-4 py-4 sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
          <Breadcrumb pages={pages} />
        </div>

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {items.map((item) => (
            <div
              key={item.id}
              className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
            >
              <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
                <img
                  src={`/images/items/${item.sku}.png`}
                  alt={item.sku}
                  loading="lazy"
                  className="h-full w-full object-contain object-center sm:h-full sm:w-full"
                />
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-sm font-medium text-gray-900">
                  <Link to={`../products/${item.sku}`}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    {item.sku}
                  </Link>
                </h3>
                <p className="text-sm text-gray-500">{item.title}</p>
                <div>
                  {item.tags.map((tag) => (
                    <Badge>{tag}</Badge>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
